import React, { useState, useEffect, useCallback } from "react";
import Header from "../Component/Header";
import { getMyAddToCart } from "../Config/firebase";
import { getAuth } from "firebase/auth";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../Config/firebase"; // Ensure you are importing your initialized Firestore instance
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Box,
  IconButton,
  TextField,
} from "@mui/material";

const ViewAddToCart = () => {
  const navigate = useNavigate();
  const [myCart, setMyCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [proceededItems, setProceededItems] = useState([]); // State to track proceeded items

  // Fetch the cart items from Firestore when component mounts
  useEffect(() => {
    fetchMyCart();
  }, []);

  // Function to fetch the user's cart from Firestore
  const fetchMyCart = async () => {
    const auth = getAuth();

    if (!auth.currentUser) {
      setError("User is not authenticated");
      setLoading(false);
      return;
    }

    try {
      const fetchedCart = await getMyAddToCart();
      const updatedCart = fetchedCart.map((item) => ({
        ...item,
        quantity: 1,
      }));
      setMyCart(updatedCart);
    } catch (error) {
      setError("Error fetching products: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle removing an item from the cart
  const handleRemoveFromCart = async (id) => {
    try {
      await deleteDoc(doc(db, "Add to Cart", id)); // Remove the item from Firestore
      setMyCart((prevCart) => prevCart.filter((item) => item.id !== id)); // Update the local state
    } catch (error) {
      console.error("Error removing item from cart:", error.message);
    }
  };

  // Handle quantity change for a specific cart item
  const handleQuantityChange = useCallback((id, type) => {
    setMyCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity: type === "increase" ? item.quantity + 1 : Math.max(1, item.quantity - 1),
            }
          : item
      )
    );
  }, []);

  // Calculate the total price of the cart
  const calculateTotal = () => {
    return myCart.reduce((total, item) => total + item.detailItem.discountPrice * item.quantity, 0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleAddToProceed = async (item) => {

    // Update item to set isProceeded to true
    const updatedCart = myCart.map((cartItem) =>
      cartItem.id === item.id ? { ...cartItem, } : cartItem
    );

    setMyCart(updatedCart);

    // Navigate to the proceed page with updated item details
    const productDetails = {
      ...item,
      subtotal: item.detailItem.discountPrice * item.quantity,
    };
    navigate("/AddToCart", { state: { productDetails} });
  };

  return (
    <>
      <Header />

      <div style={{ padding: "20px" }}>
        <Typography sx={{ textAlign: "center", mt: 3, fontWeight: "bold" }} variant="h3" gutterBottom>
          Your Cart
        </Typography>

        <Grid container spacing={3}>
          {myCart.length > 0 ? (
            myCart.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card elevation={3} style={{ padding: "5px" }}>
                  <CardContent>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Box sx={{ width: "70%" }}>
                        <img width={"70%"} src={item.detailItem.imageOne} alt={item.perfumeName} />
                      </Box>
                      <Box sx={{ width: "70%" }}>
                        <Typography variant="h5">{item.detailItem.perfumeName}</Typography>
                        <Typography variant="body1">Price: {item.detailItem.discountPrice} Rs</Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            my: 2,
                            flexWrap: 'wrap',
                            overflow: 'hidden'
                          }}
                        >
                          <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, flexShrink: 0 }}>
                            Quantity
                          </Typography>
                          <IconButton
                            sx={{
                              color: 'black',
                              width: { xs: '30px', sm: '40px' },
                              height: { xs: '30px', sm: '40px' },
                            }}
                            onClick={() => handleQuantityChange(item.id, "decrease")}
                          >
                            <Remove />
                          </IconButton>
                          <TextField
                            variant="outlined"
                            value={item.quantity}
                            size="small"
                            inputProps={{
                              style: { textAlign: 'center' },
                              readOnly: true
                            }}
                            sx={{ width: { xs: '40px', sm: '50px' }, mx: 1 }}
                          />
                          <IconButton
                            sx={{
                              color: 'black',
                              width: { xs: '30px', sm: '40px' },
                              height: { xs: '30px', sm: '40px' },
                            }}
                            onClick={() => handleQuantityChange(item.id, "increase")}
                          >
                            <Add />
                          </IconButton>
                        </Box>

                        <Typography>Subtotal: {item.detailItem.discountPrice * item.quantity} Rs</Typography>

                        <Button
                          variant="contained"
                          style={{ marginTop: "15px", background: 'black', color: 'white' }}
                          onClick={() => handleRemoveFromCart(item.id)}
                        >
                          Remove from Cart
                        </Button>
                        <Button
                          onClick={() => handleAddToProceed(item)}
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "15px", background: 'black', color: 'white' }}
                        >
                          Add to Proceed
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6">Your cart is empty.</Typography>
          )}
        </Grid>
      </div>
    </>
  );
};

export default ViewAddToCart;
