import React from "react";
import { Box, Typography } from "@mui/material";
import FrontVideo from '../video/FrontVideo.mp4'
import FrontWebVideo from '../video/FrontWebVideo.mp4'

const VideoSection = () => {
  return (
    <>
    <Box
      sx={{
        position: "relative",
        width: "100%",  // Full width of the parent container
        height: "99vh", // Full viewport height
        overflow: "hidden",
        // paddingTop: "60px",  // Adjust padding to avoid overlap with the fixed header
        display: { xs: "block", sm: "none" }
      }}
    >
      <video
        src={FrontVideo}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",  // Ensure video takes up full width
          height: "100%", // Ensure video takes up full height of the parent
          objectFit: "cover", // Ensures the video covers the full area without distortion
        }}
      ></video>
    </Box>

<Box
sx={{
  position: "relative",
  width: "100%",  // Full width of the parent container
  height: "99vh", // Full viewport height
  overflow: "hidden",
  // paddingTop: "60px",  // Adjust padding to avoid overlap with the fixed header
  display: { xs: "none", sm: "block" }
}}
>
<video
  src={FrontWebVideo}
  autoPlay
  loop
  muted
  playsInline
  style={{
    width: "100%",  // Ensure video takes up full width
    height: "100%", // Ensure video takes up full height of the parent
    objectFit: "cover", // Ensures the video covers the full area without distortion
  }}
></video>
</Box>
</>
  );
};

export default VideoSection;
